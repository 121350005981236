export interface Review {
  comment: string;
  imageLarge: string;
  imageSmall: string;
  imageUser: string;
  subtitle: string;
  title: string;
}

export enum TopicType {
  GroupGifting = 'GroupGifting',
  SmallBusiness = 'SmallBusiness',
  Curated = 'Curated',
  Personal = 'Personal',
}

export interface Topic {
  icon: string;
  heading: string;
  paragraph: string;
  type: TopicType;
}

export interface Gift {
  image: string;
  text: string;
  alt: string;
}

export interface ITestimonial {
  imageLarge: string;
  alt: string;
  copy: string;
  user: {
    firstName: string;
    title: string;
    image: string;
    city: string;
    state: string;
  };
}

export interface Link {
  label: string;
  link: string;
  icon?: string;
  index: number;
  mobileIndex: number;
}
