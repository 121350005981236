import curatedGifts from '../../../icons/curated-gifts.svg';
import groupGifting from '../../../icons/group-gifting.svg';
import localMerchants from '../../../icons/local-merchants.svg';
import superPersonal from '../../../icons/super-personal.svg';
import { TopicType, Topic } from '../../common/types';

const topics: Topic[] = [
  {
    type: TopicType.GroupGifting,
    icon: groupGifting,
    heading: 'Group gifting',
    paragraph:
      'Group gifting creates endless possibilities: increase your budget and get a gift they’ll never forget.',
  },
  {
    type: TopicType.SmallBusiness,
    icon: localMerchants,
    heading: 'From small businesses',
    paragraph: 'Shop from an array of gifts sourced exclusively from socially-conscious merchants.',
  },
  {
    type: TopicType.Curated,
    icon: curatedGifts,
    heading: 'Unique curated gifts',
    paragraph:
      'Stand out with a unique gift from our marketplace. We’ve done all the research so you don’t have to!',
  },
  {
    type: TopicType.Personal,
    icon: superPersonal,
    heading: 'Super personal',
    paragraph:
      'Photos, videos, GIFs, notes, audio messages…you name it! Customize a free card with any gift purchase.',
  },
];

export default topics;
